  import { useEffect, useState } from "react";
  import logoManantial from "../assets/navlogo.png";
  import logoHeadeScollNavbar from "../assets/LogoCompletoVino.png"
  import videoManantial from "../assets/videoManantial.webm";
  import { useLanguage } from "../hooks/useContexts";
import { useIsWeb } from "../hooks/isWeb";
  import useLocalStorage from "../hooks/useLocalStorage";
  
  export default function Hero({ imgUrl, title,retrunPage,deletTitle }) {
    const [isScroll, setIsScroll] = useState(false);
    const { languageData ,setLanguage} = useLanguage()
    let isWeb = useIsWeb();
    
    const [value, setValue] = useLocalStorage('selectedLanguage', 'ES');// value default language
    
    
    const handleLanguageChange = (event) => {
     setValue(event.target.value)
    };
   
    
    useEffect(() => {
      function handleScroll() {
        setIsScroll(window.scrollY > 0);
      }
  
      // Agregar un event listener para el evento de scroll
      window.addEventListener("scroll", handleScroll);
     
      // Remover event listener
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
    
    useEffect(()=>{ // controlle language
      setLanguage(value)
    },[value,setLanguage])
    
    return (
      <>
        <header className="relative bg-cover bg-center bg-no-repeat min-h-screen flex flex-col overflow-hidden">
          {/* Imagen de fondo */}
          {!imgUrl ? (
            // Si no hay URL, muestra el video
            <div className="absolute top-0 left-0 w-full h-full">
              <video className="object-cover w-full h-full" autoPlay muted loop>
                <source src={videoManantial} type="video/webm " alt="Video del Hotel El Manantial del Silencio, Purmamarca Jujuy" />
              </video>
              <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black/30"></div>
            </div>
          ) : (
            // Si hay URL, muestra la imagen
            <div className="absolute top-0 left-0 w-full h-full">
              <img
                src={imgUrl}
                className="object-cover w-full h-full image-zoom"
                alt={title}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black/30"></div>
            </div>
          )}

          {/* Contenido superpuesto */}
          {!isScroll ? (
            <div className="flex justify-between text-white relative sm:px-7 px-3 pt-3">
              {/* Botón en la esquina superior derecha */}

              <div className="justify-center items-center text-center my-4 ">
                <a href={retrunPage ? retrunPage : "/"}>
                  <img
                    src={logoManantial}
                    className=" sm:pl-4 h-[50px]"
                    alt="El Manantial del Silencio, Hotel Purmamarca Jujuy"
                  />
                </a>
              </div>

              <div className="items-center justify-center">
                <a
                  href={languageData.hero[0].to}
                  className=" p-4 mt-7 pb-2 text-center justify-center items-center font-libre-baskerville bg-vino border-white border-1 sticky uppercase text-white "
                >
                  {languageData.hero[0].name}
                </a>
              </div>
            </div>
          ) : (
            <div
              style={{ position: "fixed", width: "100vw", zIndex: 999 }}
              className="navbar-fixed flex justify-between text-white relative sm:px-7 px-3 pt-3"
            >
              {/* Botón en la esquina superior derecha */}
              <div className="justify-center items-center text-center my-4 ">
                <a href={retrunPage ? retrunPage : "/"}>
                  <img
                    src={logoHeadeScollNavbar}
                    className=" sm:pl-2 -mt-2 h-[50px]"
                    alt="Logo"
                  />
                </a>
              </div>


              <div
                
                className={`flex items-center  ${
                  isWeb ? "left-[37%] -mt-5" : "mt-14 left-[28%]"
                } relative`}
              >
               <span className="absolute left-4 top-0 bottom-0 flex items-center pr-2 pointer-events-none text-white">
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.29289 7.29289C6.68342 6.90237 7.31658 6.90237 7.70711 7.29289L10 9.58579L12.2929 7.29289C12.6834 6.90237 13.3166 6.90237 13.7071 7.29289C14.0976 7.68342 14.0976 8.31658 13.7071 8.70711L10.7071 11.7071C10.3166 12.0976 9.68342 12.0976 9.29289 11.7071L6.29289 8.70711C5.90237 8.31658 5.90237 7.68342 6.29289 7.29289Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <select value={value} onChange={handleLanguageChange} className="bg-transparent text-vino border-none appearance-none focus:ring-0 cursor-pointer">
                  <option style={{ backgroundColor: "transparent" }} value="ES">
                    ES
                  </option>
                  <option style={{ backgroundColor: "transparent" }} value="EN">
                    EN
                  </option>
                </select>
               
              </div>

              <div className=" items-center justify-center mt-5 mr-4">
                <a
                  href={languageData.hero[0].to}
                  className="rounded-sm p-4 mt-7 pb-2 text-center justify-center items-center font-libre-baskerville bg-vino border-white border-1 sticky uppercase text-white "
                >
                  {languageData.hero[0].name}
                </a>
              </div>
            </div>
          )}

          <div className="relative flex text-center justify-center h-screen items-center">
            <p className=" sm:text-5xl text-3xl text-white font-libre-baskerville italic ">
           {deletTitle?'': languageData.hero[1].name }  {/* {!title ? languageData.hero[1].name : title} */}
            </p>
          </div>
        </header>
      </>
    );
  }
